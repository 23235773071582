import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const McdowellSonoran = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/dVqgEI4R70E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>McDowell Sonoran Conservancy</h1>
          <p>
            Assuring the future of the Sonoran Desert and the impact of Science,
            Education and Stewardship based in the unique ecosystem that is
            Arizona and Scottsdale’s McDowell Sonoran Preserve. The McDowell
            Sonoran Conservancy preserves and advances natural open space
            through science, education and stewardship. Donations from M3F helps
            us in supporting our flora and fauna conservation projects within
            the Preserve. Donations also help our Education Team share the
            wonders of the McDowell Sonoran Preserve with local students through
            field trips and engaging interactive experiences that we bring to
            them right in the classroom.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/mcdowellsonoranconservancy/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/mcdowellsonoranconservancy/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/mcdowellsonoran"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default McdowellSonoran;
