import React from "react";
import "../../stylesheet/Style.css";
import BeneHeader from "../../assets/images/Fifth.mov";
import Typewriter from "../../components/typewriter/TypeWriter";

const BeneficiariesHeader = () => {
  const url =
    "https://lifeisbeautiful.com/wp-content/uploads/2022/02/billie-shadows.jpg";
  return (
    <div
      style={{
        backgroundColor: "var(--darkblue)",
      }}
    >
      <div className="beneficiaries-backimg">
        <video
          src={BeneHeader}
          typeof="video/mov"
          autoPlay
          loop
          muted
          playsInline
        />
        <Typewriter word={"Beneficiaries"} />
      </div>
    </div>
  );
};

export default BeneficiariesHeader;
