import React from 'react'
import EducationData from '../../assets/data/education';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const Education = () => {

  const NextArrow = ({ onClick }) => {
    return (
      <div className="education-arrow education-arrow-right" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  }

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="education-arrow education-arrow-left" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  }

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1052,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 704,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <div className='education-container'>
      <h1>Education</h1>
      <hr className='hr__two' />
      <p>M3F supports initiatives in Arizona to ensure that all students have access to the resources they need for a quality education.</p>
      <Slider {...settings}>
        {EducationData.map(item => (
          <div key={item.id} className='bene-container' id='education-container'>
            <div className="bene-card">
              <img src={item.img} className="bene-card-img" />
              <div className="bene-content">
                <button className="bene-learn-more" id="read-more-btn">
                  <span className="bene-circle" aria-hidden="true">
                    <span className="bene-icon bene-arrow"></span>
                  </span>
                  <a href={item.link}>
                    <span className="bene-button-text">Learn More</span></a>
                </button>
              </div>
            </div>
            <div className="bene-content-mobile">
              <button className="bene-learn-more" id="read-more-btn">
                <span className="bene-circle" aria-hidden="true">
                  <span className="bene-icon bene-arrow"></span>
                </span>
                <a href={item.link}>
                  <span className="bene-button-text">Learn More</span></a>
              </button>
            </div>
          </div>
        )
        )}

      </Slider>
    </div>
  )
}

export default Education