import React from 'react'
import GetFunded from "../../assets/images/CharityHeaderEnv.gif"
import Typewriter from '../../components/typewriter/TypeWriter'

const GetFundedHeader = () => {

  return (
    <div>
      <div className="getfunded-backimg">
        <img src={GetFunded } alt="background img" />
        <Typewriter word={"Get Funded"} />
      </div>
    </div>
  )
}

export default GetFundedHeader