import React from "react";

const Vision = () => {
  return (
    <div className="vision-container">
      <div className="visionTextdiv">
        <h1>
          Our vision at <span>M3F</span><br />
          is to support the 3 C’s:<br /><span>Community, Culture, and Charity.</span>
        </h1>
      </div>
    </div>
  );
};

export default Vision;
