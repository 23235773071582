import BoysHopeGH from "../images/BoysHope.jpg"
import JuniorAchievementImg from '../images/JuniorAchPS.jpg'
import ElevatePhoenix from '../images/JuniorAch.jpg'

const education = [
    {
      id: 1,
      name: "Boys Hope Girls Hope",
      desc: "Nurtures and guides motivated young people in need to become well-educated, career-ready men and women for others.",
      img: BoysHopeGH,
      link: "/beneficiaries/boyshope",
    
     
    },
    {
        id: 2,
        name: "Junior Achievement of Arizona",
        desc: "Inspires and prepares young people to succeed in a global economy.",
        img: JuniorAchievementImg,
        link: "/beneficiaries/juniorach",
        
      },
      {
        id: 3,
        name: "Elevate Phoenix",
        desc: "We are a Phoenix non-profit delivering long-term, life-changing relationships with urban youth.",
        img: ElevatePhoenix,
        link: "/beneficiaries/elevatephoenix",
      },
]

    export default education