import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";

const FriendsOfVerde = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/o4_QO_xkp0c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Friends of the Verde River</h1>
          <p>
            The Verde River is one of Arizona’s few remaining flowing rivers.
            The river and its tributaries, such as Granite Creek, Sycamore
            Creek, Oak Creek, and Fossil Creek, provide lush corridors of life
            as they wind their way through beautiful, iconic, and arid
            landscapes. The Verde River system is an environmental and cultural
            treasure for local communities, the state of Arizona, and beyond.
            The donation from M3F brings more children to the Verde Valley
            Bioblitz funding field trips including follow-up classroom visits,
            as well as riparian restoration/invasive treatment or sediment
            reduction work. We hire both military veterans and Arizona
            Conservation Corps crews to do this work.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/friendsoftheverderiver/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/verderiverfriends/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/verdefriends"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default FriendsOfVerde;
