import React from 'react'
import '../../stylesheet/Style.css'
import Partnersheader from './PartnersHeader'
import wespac from '../../assets/images/wespacblack.webp'
import PartnersLogo from './PartnersLogo'


const Partners = () => {

  return (
    <section>
      <Partnersheader />
      <div className="partnersDiv">
        <p>M3F would not be the same without the support of our sponsors and partners.</p>
        <img src={wespac} alt="sponser logo" />
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur ut fugit nostrum excepturi molestias quibusdam iusto dicta enim sint nam, quisquam nulla repellat eius fuga accusamus omnis aliquam facere sequi.</p> */}
      </div>
      <hr />
      <PartnersLogo />
    </section>
  )
}

export default Partners