import React from 'react'
import CommunityData from '../../assets/data/community';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const Community = () => {


  const NextArrow = ({ onClick }) => {
    return (
      <div className="community-arrow community-arrow-right" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  }

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="community-arrow community-arrow-left" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1052,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 704,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <div className='community-container'>
      <h1>Community</h1>
      <hr />
      <p>Whether it's supporting initiatives in healthcare, or providing resources for the homeless, M3F has been taking action in our community since our inception.</p>
      <Slider {...settings}>
        {CommunityData.map(item => (
          <div key={item.id} className='bene-container' id='community-container'>
            <div className="bene-card">
              <img src={item.img} className="bene-card-img" />
              <div className="bene-content">
                <button className="bene-learn-more" id="read-more-btn">
                  <span className="bene-circle" aria-hidden="true">
                    <span className="bene-icon bene-arrow"></span>
                  </span>
                  <a href={item.link}>
                    <span className="bene-button-text">Learn More</span></a>
                </button>
              </div>
            </div>
            <div className="bene-content-mobile">
              <button className="bene-learn-more" id="read-more-btn">
                <span className="bene-circle" aria-hidden="true">
                  <span className="bene-icon bene-arrow"></span>
                </span>
                <a href={item.link}>
                  <span className="bene-button-text">Learn More</span></a>
              </button>
            </div>
          </div>
        )
        )}

      </Slider>
    </div>
  )

}

export default Community