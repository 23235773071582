
import FreeArts from '../images/FreeArtsFor.jpg'
import BacklinePhoenixFinal from '../images/Backline.jpg'
import RosiesHouseImg from "../images/Rosie'sHousePS.gif"


const arts = [
  {
    id: 1,
    name: "Rosie's House",
    desc: "Envisions a community where all youth have access to music education and opportunities for lifetime achievement.",
    img: RosiesHouseImg,
    link: "/beneficiaries/rosieshouse",

  },
  {
    id: 2,
    name: "Backline",
    desc: "Provides mental health & wellness resources for the music industry.",
    img: BacklinePhoenixFinal,
    link: "/beneficiaries/backline",

  },
  // {
  //   id: 3,
  //   name: "Artist Advocacy Foundation",
  //   desc: "Looks to support, produce and promote artists, events and programs that aspire to inspire.",
  //   img: 

  // },
  {
    id: 4,
    name: "Free Arts",
    desc: "Art has the power to transform lives. That's why we make it our mission to connect children who have experienced trauma with resilience-building arts programs and caring adult mentors.",
    img: FreeArts,
    link: "/beneficiaries/freearts",

  }
]

export default arts