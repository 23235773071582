import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const HospiceOTV = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/cfcZ0TfoehI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Hospice of the Valley</h1>
          <p>
            As a not-for-profit, community-based hospice, we strive to serve the
            diverse needs of families with a wide variety of comprehensive
            programs. We care for people in Maricopa and northern Pinal
            counties. With no investors to pay, Hospice of the Valley can create
            community programs that honor veterans, and help families struggling
            with chronic illness like Alzheimer’s, cancer and heart disease.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/hospiceofthevalleyaz/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/HospiceoftheValley"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.youtube.com/user/HospiceofValleyAZ"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default HospiceOTV;
