import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import FollowYourHeartLogo from "../../assets/images/followyourheartlogo.jpg";
import ReactPlayer from "react-player/youtube";

const FollowYourHeart = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/UZkzGNHixXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Follow Your Heart Animal Rescue</h1>
          <p>
            To us, Follow Your Heart is more than just a name; it is our driven
            purpose. We are an all foster based rescue in Mesa, Arizona. By
            foster, we mean that there are loving people who willingly take in
            these beautiful transient creatures and do their very best to
            provide them with safety, security and love. We have 10 unpaid board
            members, some of which have full time jobs in addition to their
            services to the rescue. Funds from M3F spay/neuter, microchip,
            vaccinate, and care for around 50 rescue dogs until they find their
            forever home and provide medical care to dogs that need emergency or
            long-term care.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/followyourheart_rescue/?igshid=v26ent31pdt6"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/FollowYourHeartAnimalRescue/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default FollowYourHeart;
