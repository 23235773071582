import React from 'react'
import EnvironmentData from '../../assets/data/environment';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const Environment = () => {

  const NextArrow = ({ onClick }) => {
    return (
      <div className="environment-arrow environment-arrow-right" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  }

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="environment-arrow environment-arrow-left" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1052,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 704,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <div className='environment-container'>
      <h1>Environment</h1>
      <hr className='hr__two' />
      <p>We provide support to nonprofits working to tackle environmental issues and promote positive change on our planet.</p>
      <Slider {...settings}>
        {EnvironmentData.map(item => (
          <div key={item.id} className='bene-container' id='environment-container'>
            <div className="bene-card">
              <img src={item.img} className="bene-card-img" />
              <div className="bene-content">
                <button className="bene-learn-more" id="read-more-btn">
                  <span className="bene-circle" aria-hidden="true">
                    <span className="bene-icon bene-arrow"></span>
                  </span>
                  <a href={item.link}>
                    <span className="bene-button-text">Learn More</span></a>
                </button>
              </div>
            </div>
            <div className="bene-content-mobile">
              <button className="bene-learn-more" id="read-more-btn">
                <span className="bene-circle" aria-hidden="true">
                  <span className="bene-icon bene-arrow"></span>
                </span>
                <a href={item.link}>
                  <span className="bene-button-text">Learn More</span></a>
              </button>
            </div>
          </div>
        )
        )}

      </Slider>
      <div className="custom-shape-divider-bottom-1659657836">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
        </svg>
      </div>
    </div>
  )
};

export default Environment