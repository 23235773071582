import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const LandLSociety = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/Q8jKkY3LbDw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Leukemia and Lymphoma Society</h1>
          <p>
            <ul>
              <li>
                The Leukemia & Lymphoma Society (LLS) is the global leader in
                the fight against blood cancer.
              </li>
              <br></br>
              <li>
                LLS funds lifesaving blood cancer research around the world,
                provides free information and support services, and is the voice
                for all blood cancer patients seeking access to quality,
                affordable, coordinated care.
              </li>
              <br></br>
              <li>
                In 2020-2021 over 5 million dollars in co-pay assistance and
                over $140,000 in travel assistance was awarded to Arizona
                patients.
              </li>
            </ul>
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/llsusa/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/LLSusa"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.youtube.com/user/LeukemiaLymphomaSoc"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default LandLSociety;
