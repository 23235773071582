import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";

const StVincent = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/WU1fvLgZzNs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>St Vincent De Paul</h1>
          <p>
            Members of the Society of St. Vincent de Paul (known as
            “Vincentians”) are men and women who strive to grow spiritually by
            offering person-to-person service to neighbors in need. Our members
            represent all ages, every race, and all incomes, and all are gifted
            with awareness that the blessings of time, talent, and treasure are
            to be shared with neighbors in need. The donation from M3F helps St.
            Vincent de Paul feed, clothe, house and heal, supporting multiple
            SVdP programs for the Valley community. Specifically, it makes
            possible hot meals in our charity dining rooms and services out of
            our Resource Center, like showers, clothing, hygiene items and more.
            It also goes toward providing shelter and delivering food boxes and
            rent and utility assistance to people in need.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/svdpusa/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/svdpusa/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/svdpusacouncil"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default StVincent;
