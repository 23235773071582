import React from 'react'
import "../../stylesheet/Style.css"
import HomepageQuote from './HomepageQuote';
import ImpactSection from './ImpactSection';
import Beginnings from './Beginnings';
import Vision from './Vision';
import CharityHomepageShutterstock from '../../assets/images/CharityHomepageShutterstock.mp4'
import { Typewriter } from 'react-simple-typewriter'


const Homepage = () => {

  return (
    <div>
      <div className="homepage-backimg">
        <video className="video-background" src={CharityHomepageShutterstock} typeof='video/mp4' autoPlay loop muted playsInline />
        <div className='homepage-typewriter'>
          <h1><Typewriter
            cursor
            cursorStyle='|'
            delaySpeed={2000}
            typeSpeed={200}
            words={['Welcome to the M3F Fund']} />
          </h1>
        </div>
        <div className="custom-shape-divider-bottom-1663032429">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div>

      </div>
      <HomepageQuote />
      <Vision />
      <Beginnings />
      <ImpactSection />
    </div>
  )
}

export default Homepage