import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const ElevatePheoenix = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/m1FB_2Dy6J8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Elevate Phoenix</h1>
          <p>
            If you consider yourself successful, chances are, somewhere along
            your journey there were people who encouraged or pushed you towards
            your potential. It may have been a parent, a teacher, a relative, or
            a mentor. Unfortunately, many of today’s urban youth lack this
            positive guidance resulting in alarming school dropouts, teen
            pregnancy and higher incarceration rates. This is where Elevate
            Phoenix comes in. We provide meaningful relationships, opportunities
            and experiences that shape students into the young adults we know
            they can become. Donations from M3F were used to purchase laptops
            for our post-secondary RISE College Bridge Program allowing an
            increase in GPA, and paid for scholarship recipients to earn college
            credits.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/elevatephoenix/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/elevatephoenixaz/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/elevatephoenix"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default ElevatePheoenix;
