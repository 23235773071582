import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";

const AzHelpingHands = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/znTzQIM-8Nc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Arizona Helping Hands</h1>
          <p>
            We provide children with a safe place to sleep and more, including
            twin beds, cribs, linens, clothing, diapers, wipes, educational
            activities, and personal hygiene kits. We prepare children for
            success in school with backpacks and school supplies. We also
            celebrate children with personalized birthday packages filled with
            toys, games, books, and puzzles to lift their self-esteem.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/azhelpinghands/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/azhelpinghands"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCvoM3eKjKPTak48TIZ2Hurw"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default AzHelpingHands;
