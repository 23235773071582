import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import ArtistAdvocacy from "./pages/beneficiaries/ArtistAdvocacyFoundation";
import Backline from "./pages/beneficiaries/Backline";
import Beneficiaries from "./pages/beneficiaries/Beneficiaries";
import GetFunded from "./pages/getfunded/GetFunded";
import Homepage from "./pages/homepage/Homepage";
import Partners from "./pages/partners/Partners";
import Stories from "./pages/stories/Stories";
import BoysHope from "./pages/beneficiaries/BoysHope";
import HabitatForHumanity from "./pages/beneficiaries/HabitatForHumanity";
import HospiceOTV from "./pages/beneficiaries/HospiceOTV";
import JuniorAchievement from "./pages/beneficiaries/JuniorAchievement";
import MentorKids from "./pages/beneficiaries/MentorKids";
import RosiesHouse from "./pages/beneficiaries/RosiesHouse";
import PhoenixChildrens from "./pages/beneficiaries/PhoenixChildrens";
import BarrowNeuro from "./pages/beneficiaries/BarrowNeuro";
import Umom from "./pages/beneficiaries/Umom";
import PhoenixRescue from "./pages/beneficiaries/PhoenixRescue";
import PopulationConnection from "./pages/beneficiaries/PopulationConnection";
import Lifewell from "./pages/beneficiaries/Lifewell";
import ElevatePheoenix from "./pages/beneficiaries/ElevatePhoenix";
import FreeArts from "./pages/beneficiaries/FreeArts";
import AzHelpingHands from "./pages/beneficiaries/AzHelpingHands";
import NewPathways from "./pages/beneficiaries/NewPathways";
import SavingAmy from "./pages/beneficiaries/SavingAmy";
import BigBrothers from "./pages/beneficiaries/BigBrothers";
import ChildCrisis from "./pages/beneficiaries/ChildCrisis";
import DesertFoothills from "./pages/beneficiaries/DesertFoothills";
import FollowYourHeart from "./pages/beneficiaries/FollowYourHeart";
import FriendsOfVerde from "./pages/beneficiaries/FriendsOfVerde";
import NotMyKid from "./pages/beneficiaries/NotMyKid";
import Mcdowellsonoran from "./pages/beneficiaries/McdowellSonoran";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import TGen from "./pages/beneficiaries/TGen";
import StVincent from "./pages/beneficiaries/StVincent";
import StMarys from "./pages/beneficiaries/StMarys";
import UnitedWay from "./pages/beneficiaries/UnitedWay";
import Footer from "./components/footer/Footer";
import AmericanHeartAssociation from "./pages/beneficiaries/AmericanHeartAssociation";
import LandLSociety from "./pages/beneficiaries/LandLSociety";
import PacificSalmon from "./pages/beneficiaries/PacificSalmonFoundation";






function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/beneficiaries" element={<Beneficiaries />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/stories" element={<Stories />} />
        <Route path="/getfunded" element={<GetFunded />} />
        <Route path="/shop" element={<ComingSoon />} />
        <Route path="/beneficiaries/backline" element={<Backline />} />
        <Route path="/beneficiaries/boyshope" element={<BoysHope />} />
        <Route path="/beneficiaries/habitat" element={<HabitatForHumanity />} />
        <Route path="/beneficiaries/hospiceotv" element={<HospiceOTV />} />
        <Route path="/beneficiaries/juniorach" element={<JuniorAchievement />} />
        <Route path="/beneficiaries/mentorkids" element={<MentorKids />} />
        <Route path="/beneficiaries/rosieshouse" element={<RosiesHouse />} />
        <Route path="/beneficiaries/phoenixchildrens" element={<PhoenixChildrens />} />
        <Route path="/beneficiaries/barrowneuro" element={<BarrowNeuro />} />
        <Route path="/beneficiaries/artistsadvo" element={<ArtistAdvocacy />} />
        <Route path="/beneficiaries/umom" element={<Umom />} />
        <Route path="/beneficiaries/phoenixrescue" element={<PhoenixRescue />} />
        <Route path="/beneficiaries/popconnect" element={<PopulationConnection />} />
        <Route path="/beneficiaries/lifewell" element={<Lifewell />} />
        <Route path="/beneficiaries/elevatephoenix" element={<ElevatePheoenix />} />
        <Route path="/beneficiaries/freearts" element={<FreeArts />} />
        <Route path="/beneficiaries/azhelpinghands" element={<AzHelpingHands />} />
        <Route path="/beneficiaries/newpathways" element={<NewPathways />} />
        <Route path="/beneficiaries/savingamy" element={<SavingAmy />} />
        <Route path="/beneficiaries/bigbrothers" element={<BigBrothers />} />
        <Route path="/beneficiaries/childcrisis" element={<ChildCrisis />} />
        <Route path="/beneficiaries/desertfoothills" element={<DesertFoothills />} />
        <Route path="/beneficiaries/followyourheart" element={<FollowYourHeart />} />
        <Route path="/beneficiaries/friendsofverde" element={<FriendsOfVerde />} />
        <Route path="/beneficiaries/notmykid" element={<NotMyKid />} />
        <Route path="/beneficiaries/mcdowellsonoran" element={<Mcdowellsonoran />} />
        <Route path="/beneficiaries/tgen" element={<TGen />} />
        <Route path="/beneficiaries/stvincent" element={<StVincent />} />
        <Route path="/beneficiaries/stmarys" element={<StMarys />} />
        <Route path="/beneficiaries/unitedway" element={<UnitedWay />} />
        <Route path="/beneficiaries/americanheart" element={<AmericanHeartAssociation />} />
        <Route path="/beneficiaries/landlsociety" element={<LandLSociety />} />
        <Route path="/beneficiaries/pacificsalmon" element={<PacificSalmon />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
