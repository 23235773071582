import AzHelpingHands from '../images/AZHelpingHandsPS.jpg'
import HabitatImg from '../images/HabitatforHumanityPS.jpg'
import IvyBrain from '../images/IvyBrainTumorCenterPS.jpg'
import LifewellImg from '../images/Lifewell.jpg'
import HospiceOtvImg from '../images/HospiceoftheValley.jpg'
import MentorKids3 from '../images/MentorKids.jpg'
import NewPathways from '../images/NewPathwaysforFYPS.jpg'
import PhoenixRescueImg from "../images/PRMPS.jpg"
import SavingAmyImg from '../images/SavingAmyPS.jpg'
import BigBrotherBigSistersofAZ from '../images/BigBrothers.jpg'
import ChildCrisisofAZ from '../images/ChildChrisis.JPG'
import FollowYourHeart from '../images/FollowYourHeart.jpg'
import notMYkid from '../images/NotMyKid.jpg'
import TGen from '../images/Tgen.jpg'
import StVincentDePaul from '../images/StVincent.jpg'
import StMaryFoodBank from '../images/StMarys.jpg'
import UnitedWay from '../images/UnitedWay.jpg'
import AmericanHeart from '../images/americanheart.jpg'
import PhoenixChildrens from '../images/PhoenixChildrensPS.jpg'
import LandLSociety from '../images/LandLSociety.gif'

const community = [
    {
      id: 1,
      name: "Ivy Brain Tumor Center",
      desc: "The Ivy Brain Tumor Center is a non-profit translational research program that employs a bold clinical trials strategy to rapidly identify the most promising first-in-class therapies.",
      img: IvyBrain, 
      link: "/beneficiaries/barrowneuro",
    },
      {
        id: 2,
        name: "Habitat for Humanity",
        desc: "Helps families build and improve places to call home. We believe affordable housing plays a critical role in strong and stable communities.",
        img: HabitatImg,
        link: "/beneficiaries/habitat",
    },
    {
      id: 3,
      name: "Hospice of the Valley",
      desc: "Arizona’s leading provider of end-of-life care. Among the nation’s oldest and largest not-for-profit hospices committed to bringing comfort, dignity and compassionate care to the community.",
      img: HospiceOtvImg,
      link: "/beneficiaries/hospiceotv",
    },
    {
      id: 4,
      name: "Mentor Kids USA",
      desc: "Equips and empowers youth to be transformative leaders in their lives, their families, and their communities.",
      img: MentorKids3,
      link: "/beneficiaries/mentorkids"
    },
    {
      id: 5,
      name: "Phoenix Children's Hospital",
      desc: "Provides hope, healing, and the best healthcare for children and their families.",
      img: PhoenixChildrens,
      link: "/beneficiaries/phoenixchildrens",
    },
    {
      id: 6,
      name: "Arizona Helping Hands",
      desc: "Basic needs and so much more! Arizona Helping Hands is the largest provider of essential necessities for children in foster care in the state.",
      img: AzHelpingHands,
      link: "/beneficiaries/azhelpinghands",
    },
    {
      id: 7,
      name: "Lifewell",
      desc: "Lifewell aims to provide hope and recovery through our person-centered residential, outpatient, housing and community living programs. We provide integrated healthcare services to low-income adults with behavioral and physical illness and substance use disorders.",
      img: LifewellImg,
      link: "/beneficiaries/lifewell",
    },
    {
      id: 8,
      name: "New Pathways for Youth",
      desc: "Our Mission: Guiding youth as they discover renewed outlooks on life and realize unwritten possibilities for their futures",
      img: NewPathways,
      link: "/beneficiaries/newpathways",
    },
    {
      id: 9,
      name: "Phoenix Rescue Mission",
      desc: "The Phoenix Rescue Mission is a place of hope, healing, and new beginnings for men, women, and children in our community struggling with homelessness, addiction, and trauma.",
      img: PhoenixRescueImg,
      link: "/beneficiaries/phoenixrescue",
    },
    {
      id: 10,
      name: "Saving Amy",
      desc: "We envision a world where all people come together as one community; empowering each other to experience life changing ways of living through inner transformation, compassion and love.",
      img: SavingAmyImg,
      link: "/beneficiaries/savingamy",
    },
    {
      id: 11,
      name: "Big Brothers, Big Sisters of AZ",
      desc: "Our mission is to create and support one-to-one mentoring relationships that ignite the power and promise of youth. ",
      img: BigBrotherBigSistersofAZ,
      link: "/beneficiaries/bigbrothers",
    },
    {
      id: 12,
      name: "Child Crisis of AZ",
      desc: "Child Crisis Arizona’s mission is to provide children and youth in Arizona a safe environment, free from abuse and neglect, by creating strong and successful families.",
      img: ChildCrisisofAZ,
      link: "/beneficiaries/childcrisis",
    },
    {
      id: 13,
      name: "Follow Your Heart Animal Rescue",
      desc: "Follow Your heart Animal Rescue is a 501(c) (3) organization with a simple mission- saving animals.",
      img: FollowYourHeart,
      link: "/beneficiaries/followyourheart",
    },
    {
      id: 14,
      name: "notMYkid",
      desc: "notMYkid is a 501(c)(3) nonprofit organization that provides children and families with truly lifesaving programs, support, resources, and education.",
      img: notMYkid,
      link: "/beneficiaries/notmykid",
    },
    {
      id: 15,
      name: "TGen",
      desc: "We Are Dedicated To Conducting Groundbreaking Medical Research With Life-Changing Results. We Work To Unravel The Genetic Components Of Common And Complex Diseases.",
      img: TGen,
      link: "/beneficiaries/tgen",
    },
    {
      id: 16,
      name: "St Vincent De Paul",
      desc: "The mission of the Society of St. Vincent de Paul is “a network of friends, inspired by Gospel values, growing in holiness and building a more just world through personal relationships with and service to people in need.",
      img: StVincentDePaul,
      link: "/beneficiaries/stvincent",
    },
    {
      id: 17,
      name: "St Mary's Food Bank",
      desc: "St. Mary’s Food Bank serves to alleviate hunger through the gathering and distribution of food while encouraging self-sufficiency, collaboration, advocacy and education.",
      img: StMaryFoodBank,
      link: "/beneficiaries/stmarys",
    },
    {
      id: 18,
      name: "United Way",
      desc: "Improving lives by mobilizing communities to create lasting changes in community conditions.",
      img: UnitedWay,
      link: "/beneficiaries/unitedway",
    },
    {
      id: 18,
      name: "American Heart Association",
      desc: "",
      img: AmericanHeart,
      link: "/beneficiaries/americanheart",
    },
    {
      id: 19,
      name: "Leukemia and Lymphoma Society",
      desc: "",
      img: LandLSociety,
      link: "/beneficiaries/landlsociety",
    },

]

    export default community