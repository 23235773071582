
import mural from '../images/mission1.gif'
import garden2 from '../images/garden2.gif'
import educate2 from '../images/educate2.gif'
import environment1 from '../images/environment1.gif'


const ImpactCard = [
  {
    id: 1,
    name: "Arts",
    img: mural,
    desc: "M3F embraces and celebrates art in every form. We believe that everyone should have the opportunity and resources to bring their creativity to life. ",
    storyLink: "/beneficiaries#arts-container"
  },
  {
    id: 2,
    name: "Community",
    img: garden2,
    desc: "From supporting initiatives in healthcare, to providing resources for the homeless, M3F has been taking action in our community since our inception.",
    storyLink: "/beneficiaries#community-container"
  }, {
    id: 3,
    name: "Education",
    img: educate2,
    desc: "M3F supports initiatives in Arizona to ensure that all students have access to the resources they need for a quality education.",
    storyLink: "/beneficiaries#education-container"
  }, {
    id: 4,
    name: "Environment",
    img: environment1,
    desc: "We provide support to nonprofits working to tackle environmental issues and promote positive change on our planet. ",
    storyLink: "/beneficiaries#environment-container"
  },
]

export default ImpactCard