import React from "react";
import "../../stylesheet/Style.css";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const ArtistAdvocacy = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer url="" loop playing />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Artist Advocacy Foundation</h1>
          <p>
            Walk through any of the world’s great art museums or listen to any
            classical symphony orchestra and know that what you are seeing and
            hearing would almost certainly not exist but for the role of patrons
            and philanthropists. The Artist Advocacy Foundation serves as a
            conduit between today’s artists and patrons.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.facebook.com/ArtistAdvocacyFoundation/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default ArtistAdvocacy;
