import "../../stylesheet/cardstyle.css";
import stories from "../../assets/data/StoriesData";
import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="read-more-text">
      {isReadMore ? text.slice(0, 355) : text}
      <span onClick={toggleReadMore} className="read-more-text-hide">
        {isReadMore ? "...Read more" : " Read less"}
      </span>
    </p>
  );
};

const StoryCard = () => {
  return (
    <>
      <section className="story-bg-section">
        <h1 className="story-bg-name">
          Phoenix Children's Hospital: Henry's Story
        </h1>
        <div className="story-bg-content">
          <p>
            For months, two-and-a-half-year-old Henry struggled to speak, left
            silent from a brain aneurysm that nearly cost him his life…until one
            music therapy session changed everything. M3F underwrites the music
            therapy program at Phoenix Children’s Hospital which is funded by
            every ticket buyer who attends M3F Fest.
          </p>
        </div>
        <div className="story-bg-img">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/K0T5K-_v-V0" title="YouTube video player" frameborder="0" allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </section>
      {stories.map((story, index) => {
        return (
          <section key={index} className="story-bg-section">
            <h1 className="story-bg-name">{story.name}</h1>
            <div className="story-bg-content">
              <p>{story.desc}</p>
              {/* <p>Henry's Story</p> */}
            </div>
            <div className="story-bg-img">
              <img src={story.img} alt="story background" />
            </div>
          </section>
        );
      })}
    </>
  );
};

export default StoryCard;
