import React from 'react'
import Mission2 from '../../assets/images/mission2.gif'


const HomepageQuote = () => {

  return (

    <section className='homepage-quote-section'>
      <div className="homepage-quote-container">
        <div className="homepage-quote-content">
          <div className="homepage-quote-content-img">
            <img src={Mission2} alt="Body Building" />
          </div>
          <div className="homepage-quote-content-text">
            <h2 className="homepage-quote-content-title">Our Mission</h2>
            <p className="homepage-quote-content-paragraph">We launched the M3F Fund to exist as a year-round venture, using our platform and resources to try and make the world a better place. The M3F Fund wouldn't exist without the support of our incredible fans, as 100% of every ticket to M3F goes directly to charity. When they buy a ticket to have the time of their life at M3F Fest, they are ultimately giving back to the community around them. This is why we say: Every Ticket Counts!</p>
            {/* <a href="#" className="homepage-quote-btn">View More</a> */}
          </div>
        </div>
      </div>
    </section>
  )
}


export default HomepageQuote;