import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";

const ChildCrisis = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/MSJfo8DwJyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Child Crisis of Arizona</h1>
          <p>
            Child Crisis Arizona has been helping our community’s most
            vulnerable children, youth and families for nearly five decades. In
            times of crisis, we are there to help. When no crisis threatens, we
            are there to strengthen families, build resiliency and prevent
            crisis situations. M3F has helped Arizona’s most vulnerable children
            and families who need a safe place to lie their heads at night, warm
            food, clothing and access to early education.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="instagram.com/childcrisisaz/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/ChildCrisisAZ"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/childcrisisariz"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default ChildCrisis;
