import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const BoysHope = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/BdfGmnyfIdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Boys Hope Girls Hope</h1>
          <p>
            Boys Hope Girls Hope is an international organization that works
            with young people in need from their critical adolescent years all
            the way through college and into the launch of their careers. Some
            need a safe place to live. Others just need a supportive team of
            people and access to resources.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/bhghnetwork/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/BHGHAZ/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/bhghaz?lang=en"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default BoysHope;
