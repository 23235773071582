import React from 'react'
import "../../stylesheet/Style.css"
import StoriesHead from '../../assets/images/CharityHeaderArt.gif'
import Typewriter from '../../components/typewriter/TypeWriter'

const StoriesHeader = () => {
  return (
    <div>
      <div className="partners-backimg">
        <img src={StoriesHead} alt="background img" />
        <Typewriter word={"Stories"} />
      </div>
    </div>
  )
}

export default StoriesHeader