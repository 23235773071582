import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const NewPathways = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/ybj5Sa304Iw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>New Pathways for Youth</h1>
          <p>
            <ul>
              <li>
                Through the research-informed Holistic Youth Transformation
                Program, New Pathways for Youth connects each youth to 200+
                hours of mentoring and accompanying programming annually, as
                well as 48 hours of individualized case management.
              </li>
              <br></br>
              <li>
                The year round program serves youth participants through: six
                three-day Personal Development Retreats, six six-week Parent
                Support Workshops, eight monthly Personal Development and Life
                Skill Workshops- 96 total, three monthly Workforce
                Development/College and Career Planning Sessions -36 total, 130
                monthly Mentor Coaching and Support sessions (1,569 sessions
                total), and 400 weekly one-hour Case Management Sessions- 20,000
                sessions total
              </li>
              <br></br>
              <li>
                New Pathways for Youth is currently engaged in a five-year
                initiative to extend the impact of our Holistic Youth
                Transformation Program to 1,000 youth annually by 2026. Funding
                from McDowell Mountain Music Festival helps New Pathways for
                Youth:
              </li>
            </ul>
            <ol>
              <dt>1. Recruit, train, and support new volunteer mentors;</dt>
              <dt>2. Identify, enroll, and match new youth participants;</dt>
              <dt>
                3. Curate, implement, and evaluate the materials and supplies
                needed for increased offerings of the Holistic Youth
                Transformation Program’s six elements
              </dt>
            </ol>
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/npfyphx/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/npfyphx/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.youtube.com/user/NPFYPHX"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default NewPathways;
