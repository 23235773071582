import React from 'react'
import StoryCard from './StoriesCard'
import StoriesHeader from './StoriesHeader'




const Stories = () => {
  return (
    <div className='stories-background-section'>
      <StoriesHeader />
      <StoryCard />
    </div>
  )
}

export default Stories