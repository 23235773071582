import React from 'react'
import '../../stylesheet/Style.css'
import PartnerHeader from "../../assets/images/sixth.mov"
import Typewriter from '../../components/typewriter/TypeWriter'

const Partnersheader = () => {


    return (
        <div>
            <div className="partners-backimg">
            <video src={PartnerHeader } typeof='video/mov' autoPlay loop muted playsInline />
                <Typewriter word={"Partners"} />
            </div>
        </div>
    )
}

export default Partnersheader