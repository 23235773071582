import React from 'react'
import ImpactCardData from '../../assets/data/ImpactCard'
import { HashLink } from 'react-router-hash-link';

const ImpactSection = () => {
  return (
    <section className="impactTitle">
      <div className="impactDiv">
        <h1>Impact</h1>
     
        <p>M3F takes pride in supporting the community as one of the only 100% non-profit Music Festivals. In 2022, we welcomed 30,000+ guests and donated over $1.2 Million ($4.4 Million+ since our inception) to local charities. M3F's charitable giving focuses on four main categories: Arts, Community, Education and the Environment.</p>
      </div>


      <div className='impact-card-group'>
        {ImpactCardData && ImpactCardData.map(item => (
          <div key={item.id} className="impact-card-container">
            <div className="impact-card impact-u-clearfix">
              <div className="impact-card-body">
                <h2 className="impact-card-title">{item.name}</h2>
                <p className="impact-card-description impact-subtle">{item.desc}</p>
                <div className="impact-card-read"><HashLink smooth to={item.storyLink}>Read More</HashLink></div>
              </div>
              <img src={item.img} alt="" className="impact-card-media" />
            </div>
          </div>
        )
        )}
      </div>
    </section>
  )
}

export default ImpactSection