import React from 'react'
import { Typewriter } from 'react-simple-typewriter'
import './typewriter.css'

const TypeWriter = (props) => {
  return (
    <div className='typewriting-wrapper'>
      <h1><Typewriter
        cursor
        cursorStyle='|'
        delaySpeed={2000}
        typeSpeed={200}
        words={[props.word]} /></h1>
    </div>
  )
}

export default TypeWriter