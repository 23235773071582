import FooterLogo from "../../assets/images/M3F-Logo-Red-Texture.png";
import "./footer.css";
import { BsInstagram, BsFacebook, BsTwitterX } from "react-icons/bs";

const Footer = () => {
  return (
    <footer>
      <div className="footer-row">
        <div className="footer-col">
          <img src={FooterLogo} alt="M3F logo" className="footer-logo" />
          <p>
            M3F exists as a 100% non-profit festival to directly support
            Arizona's communities year round.
          </p>
        </div>
        <div className="footer-col">
          <h3>Menu</h3>
          <ul>
            <li>
              <a href="/beneficiaries">Beneficiaries</a>
            </li>
            <li>
              <a href="/partners">Partners</a>
            </li>
            <li>
              <a href="/stories">Stories</a>
            </li>
            <li>
              <a href="/getfunded">Get Funded</a>
            </li>
            <li>
              <a href="/shop">Shop</a>
            </li>
          </ul>
        </div>
        <div className="footer-col">
          <h3>Contact Us</h3>
          <div className="footer-social-email-links">
            <a href="mailto:info@m3ffest.com">Media Inquiries</a>
            <a href="mailto:info@m3ffest.com">Sponsorship Inquiries</a>
          </div>
        </div>
        <div className="footer-col">
          <h3>Follow Us</h3>
          <div className="footer-social-icons">
            <a
              href="https://www.instagram.com/m3ffest/?hl=en"
              rel="noreferrer"
              target="_blank"
            >
              <BsInstagram color="var(--link-color)" />
            </a>
            <a
              href="https://www.facebook.com/m3ffest/"
              rel="noreferrer"
              target="_blank"
            >
              <BsFacebook color="var(--link-color)" />
            </a>
            <a
              href="https://twitter.com/m3ffest"
              rel="noreferrer"
              target="_blank"
            >
              <BsTwitterX color="var(--link-color)" />
            </a>
          </div>
        </div>
      </div>
      <hr
        style={{
          border: "1px solid var(--navigation-bar)",
          width: "90%",
          margin: "20px auto",
        }}
      />
      <p className="footer-copyright">
        Copyright &copy; 2024, M3F Festival, All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
