import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";

const NotMyKid = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/mFMChzik-60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>notMYkid</h1>
          <p>
            Our mission for over two decades has been to empower and educate
            youth, families, and communities with the knowledge and courage to
            identify and prevent negative youth behavior. Our impact is far
            reaching. To date, we have served over 3 million individuals and aim
            to reach 100,000 annually through our statewide and national
            programs. M3F donations helped notMYkid expand services at their new
            13,000 sq ft [i]nspired well-being campus in Scottsdale. The
            donation also supported prevention programs that served 3,530 youth
            in 17 area high schools and peer support programs that directly
            impacted 72 youth who needed daily support from a Certified Peer
            Support Specialist on their recovery journey.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/notmykiddotorg/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/notMYkid/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/notmykidtweets"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default NotMyKid;
