import React, { useState } from "react";
import "./navbar.css";
import LogoFinal from "../../assets/images/M3F-Logo-Red-Texture.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    setMobileMenuActive(false);
  };

  return (
    <nav className="navbar">
      <div className="logo-container mobile-logo">
        <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={LogoFinal} alt="M3f logo" />
        </a>
      </div>
      <div
        className={`menu-icon ${mobileMenuActive ? "active" : ""}`}
        onClick={() => {
          handleClick();
          setMobileMenuActive(!mobileMenuActive);
        }}
      >
        <i className={click ? "fas fa-times" : "fas fa-music"} />
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
          <a
            href="/beneficiaries"
            className="nav-links"
            onClick={closeMobileMenu}
          >
            BENEFICIARIES
          </a>
        </li>

        <li className="nav-item">
          <a href="/partners" className="nav-links" onClick={closeMobileMenu}>
            PARTNERS
          </a>
        </li>

        <li className="nav-item">
          <a href="/stories" className="nav-links" onClick={closeMobileMenu}>
            STORIES
          </a>
        </li>

        <li className="nav-item">
          <a
            href="/getfunded"
            className="nav-links"
            onClick={closeMobileMenu}
            target="_blank"
            rel="noreferrer"
          >
            GET FUNDED
          </a>
        </li>
        <li className="nav-item">
          <a href="/shop" className="nav-links" onClick={closeMobileMenu}>
            SHOP
          </a>
        </li>
        <li className="nav-item">
          <a href="https://m3ffest.com/" className="nav-links" onClick={closeMobileMenu}>
            M3F FESTIVAL
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
