import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const DesertFoothills = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/O8svPlWIUAo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Desert Foothills Land Trust</h1>
          <p>
            Desert Foothills Land Trust was formed in 1991 by citizens who were
            concerned about the pace of development and the loss of natural
            areas in their community. Led by a volunteer board of directors and
            a small staff, the Land Trust has become a stable and well-respected
            organization working to conserve the most sensitive and important
            open spaces in the communities of Anthem, Carefree, Cave Creek,
            Desert Hills, New River, North Phoenix and North Scottsdale.
            Donations from M3F forever protect more important lands and wildlife
            habitat in our Sonoran Desert that have a high conservation value
            and may be at risk of development, offer free outdoor recreation
            opportunities and support environmental education programs and
            events to help connect people to nature.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/desertfoothillslandtrust/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="facebook.com/desertfoothillslandtrust"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/wesaveland"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default DesertFoothills;
