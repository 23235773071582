import WhiteClaw from '../images/WhiteClaw.png'
import WaltersWolf from'../images/WaltersWolf1.png'
import SaguaroSteel from '../images/SaguaroSteelIndustries.png'
import NextLevelSteel from '../images/NextLevelSteel.png'
import RattleRum from '../images/RattleRum.png'
import RummelConstruction from '../images/RummelConstruction.png'
import LHMechanical from '../images/LHMechanical.png'
import K2Electric from '../images/K2Electric.png'
import Jenco from '../images/Jenco.png'
import Insurica from '../images/Insurica.png'
import FourPeaks from '../images/FourPeaks.png'
import Cutwater from '../images/Cutwater.png'
import AKJSealants from '../images/AKJSealants.png'
import AECElectric from '../images/AECElectric.png'
import adobeDrywall from '../images/adobeDrywall.png'
import Celsius from '../images/Celsius.png'
import Coreslab from '../images/CoreslabStructures.png'
import Aspen from '../images/Aspen.png'
import Aurelius from '../images/Aurelius.png'
import Dtphx from '../images/DTPHX.png'
import Dressler from '../images/Dressler.png'
import EcoProducts from '../images/EcoProducts.png'
import Guayaki from '../images/Guayaki.png'
import Gunsight from '../images/Gunsight.png'
import Integrated from '../images/integrated.png'
import Sprague from '../images/Sprague.png'
import Moving from '../images/Moving.png'
import Magnum from '../images/Magnum.png'
import PaulJohnson from '../images/PaulJohnson.png'
import Pena from '../images/Pena.png'
import Quality from '../images/Quality.gif'
import Sunstate from '../images/Sunstate.gif'
import Suntec from '../images/Suntec.gif'
import Susies from '../images/Susies.gif'
import Trademark from '../images/Trademark.gif'
import Waste from '../images/Waste.gif'
import Solflower from '../images/SolFlower.gif'
import Ogeez from '../images/Ogeez.gif'
import EnterpriseBank from '../images/EnterpriseBank1.gif'
import Ryan from '../images/RyanMechanical.gif'
import Hardrock from '../images/Hardrock1.gif'
import MountainHigh from '../images/MountainHigh.gif'
import ProgressiveRoofing from '../images/ProgressiveRoofing.gif'
import United from '../images/United.gif'
import WholesaleFloors from '../images/WholesaleFloors.gif'
import Spectrum from '../images/Spectrum.gif'
import Riggs from '../images/Riggs.gif'


const partnerslogo = [{
  id: 1,
  name: adobeDrywall,

},
{
  id: 2,
  name: AECElectric,
},
{
  id: 3,
  name: AKJSealants,
},
{
  id: 3,
  name: Aspen,
},
{
  id: 3,
  name: Aurelius,
},

{
  id: 4,
  name: Celsius,
},
{
  id: 4,
  name: Coreslab,
},
{
  id: 5,
  name: Cutwater,
},
{
  id: 6,
  name: Dressler,
},
{
  id: 6,
  name: Dtphx,
},
{
  id: 6,
  name: EcoProducts,
},
{
  id: 6,
  name: EnterpriseBank,
},
{
  id: 7,
  name: FourPeaks,
},
{
  id: 7,
  name: Guayaki,
},
{
  id: 7,
  name: Gunsight,
},
{
  id: 7,
  name: Hardrock,
},

{
  id: 9,
  name: Insurica,

},
{
  id: 9,
  name: Integrated,

},
{
  id: 10,
  name: Jenco,
},
{
  id: 11,
  name: Moving,
},
{
  id: 12,
  name: K2Electric,
},
{
  id: 13,
  name: LHMechanical,
},
{
  id: 11,
  name: Magnum,
},
{
  id: 11,
  name: MountainHigh,
},
{
  id: 14,
  name: NextLevelSteel,
},
{
  id: 14,
  name: Ogeez,
},
{
  id: 14,
  name: PaulJohnson,
},
{
  id: 14,
  name: Pena,
},
{
  id: 14,
  name: ProgressiveRoofing,
},
{
  id: 14,
  name: Quality,
},
{
  id: 15,
  name: RattleRum,
},
{
  id: 15,
  name: Riggs,
},
{
  id: 16,
  name: RummelConstruction,
},
{
  id: 16,
  name: Ryan,
},
{
  id: 17,
  name: SaguaroSteel,
},

{
  id: 17,
  name: Solflower,
},
{
  id: 17,
  name: Spectrum,
},
{
  id: 17,
  name: Sprague,
},
{
  id: 17,
  name: Sunstate,
},
{
  id: 17,
  name: Suntec,
},
{
  id: 17,
  name: Susies,
},
{
  id: 17,
  name: Trademark,
},
{
  id: 17,
  name: United,
},
{
  id: 18,
  name: WaltersWolf,
},
{
  id: 18,
  name: Waste,
},
{
  id: 19,
  name: WhiteClaw,
},
{
  id: 19,
  name: WholesaleFloors,
}
]

export default partnerslogo;