import React from 'react'

const ContactForm = () => {
  return (
    <div className='get-funded-contact-form'>

      {/* <form className="form"> */}
        {/* <div className="title">Fill out your form here</div>
        <div className="input-container ic2">
          <input id="email" className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="email" className="placeholder">Email</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">Name of organization</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">501 c 3 Number</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" "/>
          <div className="cut cut-short"></div>
          <label htmlFor="date" className="placeholder">Date founded (MM/DD/YYYY)</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">Mission statement</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">How the organization allocates money</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " />
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">Project timeline</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " />
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">Requested amount of money?</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="Name" className="placeholder">How the organization plans to use the donation?</label>
        </div>
        <div className="input-container ic2">
          <input className="input" type="text" placeholder=" " required/>
          <div className="cut cut-short"></div>
          <label htmlFor="link" className="placeholder">Link to 1 minute video</label>
        </div> */}
        <button type="submit" className="submit" onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeYr7190yiGsIyOhJaCRg3WvzcUhRCl08fRAAAesP4kakPdCA/viewform'}>
  APPLY HERE
</button>

        {/* <button type="submit" className="submit">APPLY</button> */}
      {/* </form> */}
    </div>
  )
}

export default ContactForm