import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const TGen = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/i61rO87l50M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>TGen</h1>
          <p>
            TGen Foundation secures and stewards private support to fuel TGen’s
            biomedical discoveries. Our mission is to positively impact the
            lives of patients and their families. The Center for Rare Childhood
            Disorders provides services at no cost to the child's family.
            Support from M3F will be put to use providing: Genomic Sequencing-
            Children with a rare disease often spend years searching for a
            diagnosis. The Center's first step in giving families hope and
            answers is to perform genome sequencing to get a blueprint of a
            child's DNA and identify the disease or disorder affecting them.
            Clinical Staffing: Our team takes all information provided by
            genomic sequencing, works to identify each child's disease or
            disorder, and guides families through diagnosis, treatment and care.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/tgenresearch/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/HelpTGen/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a href="https://twitter.com/tgen" target="_blank" rel="noreferrer">
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default TGen;
