import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";

const PhoenixChildrens = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/yn7u3v9CJmo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Phoenix Children's Hospital</h1>
          <p>
            Phoenix Children's will be the premier regional pediatric center in
            the Southwest, nationally recognized as one of the best for
            pediatric care, innovative research and medical education. We will:
            Offer the most comprehensive pediatric care services in the
            Southwest, providing a full range of services solely dedicated to
            children. Be recognized for innovative research supported by leading
            clinical trials of new treatment and diagnostic methods. Be
            recognized for providing advanced education and training for
            clinical providers. Be known as an effective advocate for Arizona’s
            children.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/phxchildrens/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/PhoenixChildrens"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.youtube.com/user/PhxChildrensHospital"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>{" "}
          rel="noreferrer"
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default PhoenixChildrens;
