import React from 'react'
import ContactForm from './ContactForm'
import GetFundedHeader from './GetFundedHeader'


const GetFunded = () => {
  return (
    <div>
      <GetFundedHeader />
      <div className='getfunded-main-container'>
        <h1>How We Fund</h1>
        <hr />
        <p>The M3F Fund will finance projects by nonprofit or 501(c)(3) organizations that have clear objectives and a measurable way to assess progress. We want to fund projects that are honest and hold themselves accountable while being transparent with how they are using the funds.</p> 

        <p>M3F prioritizes funding for 4 primary pillars: Community, Education, Arts, and Environment and project applications that concentrate efforts around these 4 core values.</p> 


        <h1>Submit your application </h1>
        <hr />

        <p>At M3F, we love learning more about the motivated individuals and organizations who are on the ground creating positive impact.</p>

        <p>If your organization is interested in applying to be a M3F beneficiary use the link below. Please submit the following: the name of your organization, your 501(c)(3) number, a detailed plan of the requested amount of money and how it will be allocated, a project timeline, and any other information you think M3F should consider.</p>
        <p>Disclaimer: M3F will not fund research projects or events.</p>

        <hr />
      </div>
      <ContactForm />
    </div>
  )
}

export default GetFunded