import React from "react";
import '../../stylesheet/Style.css'
import BeneficiariesHeader from "./BeneficiariesHeader";
import Arts from "../arts/Arts";
import Environment from "../environment/Environment";
import Community from "../community/Community";
import Education from "../education/Education";


const Beneficiaries = () => {
  return (
    <div>
      <BeneficiariesHeader /> 
      <div className="homeTextdiv">
        {/* <div className="homeTitle beneficiaries-h1"><h1 data-text="Beneficiaries">Beneficiaries</h1></div> */}
        </div>
      <Arts />
      <Environment />
      <Community />
      <Education />
    </div>
  );
};

export default Beneficiaries;