import DesertFoothills from '../images/DesertFoothillsPS3.jpg'
import FriendsofVerde from '../images/FriendsofVerdeRiver.jpg'
// import OnePercentImg from '../images/onepercentPS.jpg'
import PopulationConnection from '../images/PopulationConnectionPS.jpg'
import McDowellSonoranConservancy from '../images/McdowellSonoranPS.jpg'
import PacificSal from '../images/PacificSal.gif'


const environment = [
    // {
    //     id: 1,
    //     name: "1% For the Planet",
    //     desc: "1% for the Planet represents a global network of businesses, individuals and nonprofit organizations tackling our planet's most pressing environmental issues.",
    //     img: OnePercentImg,
       
    //   },
    {
      id: 2,
      name: "Desert Foothills Land Trust",
      desc: "Desert Foothills Land Trust connects people to nature by working with communities and partners to conserve and steward sensitive lands and species for the survival of our fragile Sonoran Desert.",
      img: DesertFoothills,
      link: "/beneficiaries/desertfoothills",

    },
    {
      id: 3,
      name: "Friends of the Verde River",
      desc: "Friends of the Verde River envisions a healthy, flowing Verde River and tributaries that support our natural environment, vibrant communities, and quality of life for future generations. We work collaboratively for a healthy, flowing Verde River system.",
      img: FriendsofVerde,
      link: "/beneficiaries/friendsofverde"
    },
    {
      id: 4,
      name: "Population Connection",
      desc: "Population Connection educates and advocates to stabilize world population at a level that can be sustained by Earth's resources.",
      img: PopulationConnection,
      link: "/beneficiaries/popconnect"
    },
    {
      id: 4,
      name: "McDowell Sonoran Conservancy",
      desc: "The McDowell Sonoran Conservancy, a nonprofit organization, has been an advocate for and champion of the Sonoran Desert for more than three decades.",
      img: McDowellSonoranConservancy,
      link: "/beneficiaries/mcdowellsonoran"
    },
    {
      id: 4,
      name: "Pacific Salmon Foundation",
      desc: "The Pacific Salmon Foundation is a non-profit charitable organization dedicated to conserving and restoring wild Pacific salmon in B.C. and the Yukon.",
      img: PacificSal,
      link: "/beneficiaries/pacificsalmon"
    },
]

    export default environment