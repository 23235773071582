import React, { useState } from 'react';
import '../../stylesheet/cardstyle.css'
import ArtsData from '../../assets/data/arts';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';



const Arts = () => {

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow arrow-right" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  }

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow arrow-left" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1052,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 704,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <div className='arts-container'>
      <h1>Arts</h1>
      <hr />
      <p>M3F embraces and celebrates art in every form. We believe that everyone should have the opportunity and resources to bring their creativity to life.</p>
      <Slider {...settings}>
        {ArtsData.map(item => (
          <div key={item.id} className='bene-container' id='arts-container'>
            <div className="bene-card">
              <img src={item.img} className="bene-card-img" />
                <div className="bene-content">
                  <button className="bene-learn-more" id="read-more-btn">
                    <span className="bene-circle" aria-hidden="true">
                      <span className="bene-icon bene-arrow"></span>
                    </span>
                    <a href={item.link}>
                      <span className="bene-button-text">Learn More</span></a>
                  </button>
              </div>
            </div>
            <div className="bene-content-mobile">
                  <button className="bene-learn-more" id="read-more-btn">
                    <span className="bene-circle" aria-hidden="true">
                      <span className="bene-icon bene-arrow"></span>
                    </span>
                    <a href={item.link}>
                      <span className="bene-button-text">Learn More</span></a>
                  </button>
              </div>
          </div>
        )
        )}

      </Slider>
      <div className="custom-shape-divider-bottom-1659658015">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>
    </div>

  )
};

export default Arts;