import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import stmaryslogo from "../../assets/images/stmaryslogo.png";

const StMarys = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <img
            className="artistImage"
            src={stmaryslogo}
            alt="artist"
            style={{ height: "39rem", width: "90%" }}
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>St. Mary's Food Bank</h1>
          <p>
            For many the past two years have been the most difficult of their
            lives. A pandemic, the loss of loved ones, spiking gas and food
            prices, record housing and rent prices, and having to reduce work
            hours or resign to serve as the caregiver of a COVID survivor are
            just some of the challenges endured. Nearly all of these situations
            also contributed to increased hunger in our state. People who never
            imagined needing food assistance came to St. Mary's for help. We use
            funds to provide 35,000 meals for low-income, hungry Arizonans
            served through 900+ partner agencies by these programs under the
            Food Distribution Program umbrella: Family Weekend Backpacks,
            Emergency Food Boxes, Mobile Pantries, Kids Feeding Programs, and
            the Senior Home Food Delivery Program.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/stmarysfoodbank/?_gl=1*10ftm9r*_gcl_aw*R0NMLjE2NjMxNjY5MjUuQ2owS0NRanc5NFdaQmhEdEFSSXNBS3hXRy1fX0pwQ2E1RUV6SWJSREo2OW9rX21zOEpvVXBjSU1DRzlDaXMxR1NKaDBoVTJERWpweWlYQWFBbnI2RUFMd193Y0I."
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/stmarysfoodbank/?_gl=1*10ftm9r*_gcl_aw*R0NMLjE2NjMxNjY5MjUuQ2owS0NRanc5NFdaQmhEdEFSSXNBS3hXRy1fX0pwQ2E1RUV6SWJSREo2OW9rX21zOEpvVXBjSU1DRzlDaXMxR1NKaDBoVTJERWpweWlYQWFBbnI2RUFMd193Y0I."
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://twitter.com/StMarysFoodBank?_gl=1*1drryw3*_gcl_aw*R0NMLjE2NjMxNjY5MjUuQ2owS0NRanc5NFdaQmhEdEFSSXNBS3hXRy1fX0pwQ2E1RUV6SWJSREo2OW9rX21zOEpvVXBjSU1DRzlDaXMxR1NKaDBoVTJERWpweWlYQWFBbnI2RUFMd193Y0I."
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default StMarys;
