import React from "react";
import "../../stylesheet/Eachpage.css";
import ReactPlayer from "react-player/youtube";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";

const BigBrothers = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/j19cgBZBP9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Big Brothers, Big Sisters of AZ</h1>
          <p>
            For more than 60 years, Big Brothers Big Sisters of Central Arizona
            has operated under the belief that inherent in every child is the
            ability to succeed and thrive in life. Big Brothers Big Sisters
            makes meaningful, professionally supported matches between adult
            volunteers (“Bigs”) and children (“Littles”), ages 6 through 18, in
            Maricopa County and Pinal County. We develop positive relationships
            that have a direct and lasting effect on the lives of young people.
            Please consider being a volunteer mentor or making a gift to help
            children in our community who need and deserve mentors build
            self-confidence and realize their potential.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.instagram.com/bbbsaz/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com//BBBSAZ/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a href="https://twitter.com/bbbsaz" target="_blank" rel="noreferrer">
            <FaTwitter
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default BigBrothers;
