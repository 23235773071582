import React from 'react'
import OneSmiley from '../../assets/images/homepage2.gif'


const Beginnings = () => {

  return (



    <section className='homepage-quote-section'>
      <div className="homepage-quote-container">
        <div className="homepage-beginnings-content">
          <div className="homepage-quote-content-img">
            <img src={OneSmiley} alt="Body Building" />
          </div>
          <div className="homepage-quote-content-text">
            <h4 className="homepage-quote-content-title">Our Beginnings</h4>
            <p className="homepage-quote-content-paragraph">Rooted in a deep love of music, the arts, and the community around us, M3F was founded with the purpose to give back. The idea was simple: two thousand fans, hundreds of lawn chairs, one stage, grandma’s cooking, and 100% of proceeds donated directly to charity. Twenty years later, we still operate under the same guiding principles, but we’ve surely grown! </p>
            {/* <a href="#" className="homepage-quote-btn">View More</a> */}
          </div>
        </div>
      </div>
    </section>
  )
}


export default Beginnings;