import React from "react";
import "../../stylesheet/Eachpage.css";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

const Lifewell = () => {
  return (
    <div className="each-single-page-container">
      <div className="each-page-left">
        <div className="each-page-mockup">
          <ReactPlayer
            url='<iframe width="560" height="315" src="https://www.youtube.com/embed/EUXFOKdoURA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            loop
            playing
          />
        </div>
      </div>
      <div className="each-page-right">
        <div className="each-page-right-content">
          <h1>Lifewell</h1>
          <p>
            Lifewell aims to provide hope and recovery through our
            person-centered residential, outpatient, housing and community
            living programs. We provide integrated healthcare services to
            low-income adults with behavioral and physical illness and substance
            use disorders.
          </p>
        </div>
        <div className="each-page-right-social">
          <a
            href="https://www.facebook.com/LifewellAZ/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCw7flhUOgCkcodeyg1W2A5A"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              color="var(--link-color)"
              size="2em"
              style={{ margin: "2rem" }}
            />
          </a>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1659732424">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Lifewell;
